import { PayloadAction } from "@reduxjs/toolkit";
import {
  combineClients,
  Entity,
  IBankAccountPermittedClient,
  IBankAccountsEntitlements,
  IClientDto,
  IDocumentClients,
  IDocumentsClient,
  IEntityDataStore,
  IInvestmentVehicle,
  IInvestmentVehicleByClientByPeriod,
  IPermittedClient,
  IPermittedEntities,
  isEntityLoaded,
  isInProgress,
  isSomething,
  IViewDataStore,
  Optional,
  PeriodHash,
  reqAvailableDocumentFilters,
  reqBankAccountsForClient,
  reqDocuments,
  reqElectionsForClient,
  reqInternalInvestmentData,
  setSelectedEntity,
} from "common";
import { put, select } from "redux-saga/effects";

import { InvestorUIStore } from "../../../../investor-ui/src/redux/store";
import { InvestorUIEntitlementStore } from "../../../../investor-ui/src/types/storetypes";

const CURRENT_ENTITY_KEY = "CURRENT_ENTITY";

export function* loadSelectedEntity(action: PayloadAction<IClientDto[]>) {
  const {
    bankAccountsEntitlementsLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
  }: InvestorUIEntitlementStore = yield select(
    (state: InvestorUIStore) => state.entitlements
  );
  const { allClients: currentStoreClients }: IViewDataStore = yield select(
    (state: InvestorUIStore) => state.viewData
  );

  const newClients = [...action.payload];

  const allClients = combineClients(currentStoreClients, newClients);

  if (allClients.length === 0 || newClients.length === 0) {
    return;
  }

  const data = localStorage.getItem(CURRENT_ENTITY_KEY);

  const defaultEntity: Entity = {
    clientId: allClients[0].id,
    investmentVehicleId: undefined,
    mdmClientId: newClients[0].mdmOId,
  };

  if (!data) {
    yield put(setSelectedEntity(defaultEntity));
    return;
  }

  const currentSelection = JSON.parse(data) as Entity;

  const canLoadCurrentSelection = allClients.find((x) => {
    return (
      x.id === currentSelection.clientId &&
      (currentSelection.investmentVehicleId === undefined ||
        x.investmentVehicles.some(
          (iv) => iv.id === currentSelection.investmentVehicleId
        ))
    );
  });

  if (canLoadCurrentSelection) {
    yield put(
      setSelectedEntity({
        ...currentSelection,
        mdmClientId: canLoadCurrentSelection?.mdmOId,
      })
    );
    return;
  }

  const allEntitlementsLoaded = !isInProgress(
    bankAccountsEntitlementsLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus
  );

  if (allEntitlementsLoaded) {
    yield put(setSelectedEntity(defaultEntity));
  }
}

export function* persistSelectedEntity(action: PayloadAction<Entity>) {
  localStorage.setItem(CURRENT_ENTITY_KEY, JSON.stringify(action.payload));
}

export function* reqInternalInvestmentDataOnEntityChange() {
  const {
    viewInternalInvestmentDataPermittedEntities,
  }: {
    selectedEntity: Optional<Entity>;
    viewInternalInvestmentDataPermittedEntities: Optional<IPermittedEntities>;
  } = yield select((state: InvestorUIStore) => state.entitlements);
  const {
    selectedEntity,
  }: {
    selectedEntity: Optional<Entity>;
  } = yield select((state: InvestorUIStore) => state.viewData);

  const {
    entities,
  }: {
    entities: IEntityDataStore[];
  } = yield select((state: InvestorUIStore) => state.internalInvestmentData);

  if (
    isSomething(selectedEntity) &&
    isSomething(viewInternalInvestmentDataPermittedEntities)
  ) {
    const matchingEntity =
      viewInternalInvestmentDataPermittedEntities.value.clients.find(
        (investmentEntity: IClientDto) =>
          investmentEntity.id === selectedEntity.value.clientId &&
          (selectedEntity.value.investmentVehicleId === undefined ||
            investmentEntity.investmentVehicles.some(
              (permittedIV: IInvestmentVehicle) =>
                permittedIV.id === selectedEntity.value.investmentVehicleId
            ))
      );

    if (matchingEntity) {
      const payload = {
        ...selectedEntity.value,
        periodId: PeriodHash.LATEST,
      } as IInvestmentVehicleByClientByPeriod;

      const isClientLoaded = isEntityLoaded(entities, payload.clientId);

      if (!isClientLoaded) {
        yield put(
          reqInternalInvestmentData({
            ...payload,
            investmentVehicleId: undefined,
          })
        );
        // TODO Improve and call concurrently get IV
      }

      const isIVLoaded =
        !payload.investmentVehicleId ||
        isEntityLoaded(entities, payload.investmentVehicleId);

      if (!isIVLoaded) {
        yield put(reqInternalInvestmentData(payload));
        return;
      }
    }
  }
}

export function* reqBankAccountDataOnEntityChange() {
  const {
    bankAccountsEntitlements,
  }: {
    bankAccountsEntitlements: IBankAccountsEntitlements;
  } = yield select((state: InvestorUIStore) => state.entitlements);
  const {
    selectedEntity,
  }: {
    selectedEntity: Optional<Entity>;
  } = yield select((state: InvestorUIStore) => state.viewData);

  if (
    !isSomething(selectedEntity) ||
    bankAccountsEntitlements.permittedBankAccountClients.length == 0
  ) {
    return;
  }

  const matchingBankAccountsClient: IBankAccountPermittedClient | undefined =
    bankAccountsEntitlements.permittedBankAccountClients.find(
      (client: IBankAccountPermittedClient) =>
        client.clientId.toLocaleString() === selectedEntity.value.clientId
    );
  if (matchingBankAccountsClient) {
    yield put(
      reqBankAccountsForClient({
        clientId: matchingBankAccountsClient.clientId.toString(),
        investmentVehicleId: undefined,
        mdmClientId: undefined,
      })
    );
  }
}

export function* reqDocumentsOnEntityChange() {
  const {
    documentClients,
  }: {
    documentClients: Optional<IDocumentClients>;
  } = yield select((state: InvestorUIStore) => state.entitlements);
  const {
    selectedEntity,
  }: {
    selectedEntity: Optional<Entity>;
  } = yield select((state: InvestorUIStore) => state.viewData);

  if (
    !isSomething(selectedEntity) ||
    !isSomething(documentClients) ||
    documentClients.value.clients.length == 0
  ) {
    return;
  }

  const matchingDocumentsClient = documentClients.value.clients.find(
    (client: IDocumentsClient) =>
      client.axiomId === selectedEntity.value.clientId
  );
  if (matchingDocumentsClient) {
    yield put(
      reqDocuments({
        clientOId: matchingDocumentsClient.oId,
        body: {
          offset: 0,
          limit: 50,
          filters: {},
          searchTerm: undefined,
          sort: undefined,
        },
      })
    );
    yield put(reqAvailableDocumentFilters(matchingDocumentsClient));
  }
}

export function* reqElectionsDataOnEntityChange() {
  const {
    permittedElectionClients,
  }: {
    permittedElectionClients: IPermittedClient[];
  } = yield select((state: InvestorUIStore) => state.entitlements);
  const {
    selectedEntity,
  }: {
    selectedEntity: Optional<Entity>;
  } = yield select((state: InvestorUIStore) => state.viewData);

  if (!isSomething(selectedEntity) || permittedElectionClients.length == 0) {
    return;
  }

  const matchingElectionsClient = permittedElectionClients.find(
    (client: IPermittedClient) =>
      client.clientId.toString() === selectedEntity.value.clientId
  );
  if (matchingElectionsClient) {
    yield put(reqElectionsForClient(matchingElectionsClient));
  }
}
